import React, { useState } from 'react';
// import Sticky from 'react-stickynode';
import { Link } from 'react-router-dom';

import { NBossCMSPage } from '@/cms/NBossCMS.js';
import Swiper from '@/components/swiper/Swiper.js';
import Slider from '@/components/slider/Slider.js';
import QuickBar from '@/components/quickbar/QuickBar.js';
import Grid from '@/components/grid/Grid.js';
import NewsLister from '@/components/news/NewsLister.js';
// import SwiperMap from '@/components/map/SwiperMap.js';

export default function MainPage(props) {
    const [page, setPage] = useState(null);

    return (
        <NBossCMSPage pageprops={props} pageDataReady={page => { setPage(page) }}>

            <div className='hidden sm:block'>

                <MainPageContent mainPageProps={page} sliderDesktopOnly />

            </div>

            <Swiper className='block sm:hidden'>

                <MainPageContent mainPageProps={page} sliderMobileOnly />

                <NewsLister data={page && page.parameterMap.newsList} />

                {/* <SwiperMap data={page && page.parameterMap.locationList} /> */}

            </Swiper>

            <TenderImage />

        </NBossCMSPage>
    );
}

export function MainPageContent(props) {
    const sliderData = (props.mainPageProps && props.mainPageProps.parameterMap.slider.value) && props.mainPageProps.parameterMap.slider;
    const boxListData = props.mainPageProps && props.mainPageProps.parameterMap.boxList;

    return (
        <div className='flex flex-col'>

            {sliderData && <Slider className='w-full max-w-tv-boxed self-center' data={sliderData} mobileOnly={props.sliderMobileOnly} desktopOnly={props.sliderDesktopOnly} />}

            <QuickBar />

            <Grid data={boxListData} />

        </div>
    );
}

function TenderImage(props) {
    return (
        <Link to='/oldal/kehop-5-2-9-16-2017-00141' className='z-40 fixed bottom-0 right-0 w-56 group' >
            <img src='https://terezvaros.hu/terezvaros-portal/methods/fmFrontendControl/getFile?key=ZmhhQMzZi94Pwm4BTIRFsMdkoXpUAVETDKlKFIBgKQjTcuYiZj' alt='KEHOP-5.2.9-16-2017-00141' className='w-full h-full object-cover transform group-hover:scale-105 transition-all duration-300 ease-in-out origin-bottom-right' />
        </Link>
    );
}